import { connection } from "./connection";

interface GetProductsParams {
  productGroups?: 'DEPOSIT',
  sectorId?: number,
  segmentId?: number, 
}

export default {
  getProducts(
    params: GetProductsParams,
  ) {
    return connection.get("portal/products/filter", {
      params: {
        ...params
      }
    });
  },
  getProductTypes () {
    return connection.get('bo/products/types')
  },
  getFeeConfigByGroup: ({ group }: { group: string }) => connection.get(`bo/products/groups/${group}/fee-configs`),
};
