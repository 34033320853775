import { connection } from '@/api/connection'

export default {
  getPortalImportedInvoiceStatusGroupClassifier: () => connection.get('bo/classifiers/portal-imported-invoice-status'),
  getFactoringInvoices: ({ contractId, history, status }) => connection.get('portal/factoring/imported/invoices', {
    params: {
      contractId,
      history,
      status
    }
  })
}
