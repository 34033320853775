import { connection } from "@/api/connection";

export default {
  getDepositProductTypes() {
    return connection.get("bo/products/deposit/types");
  },
  getDepositProducts() {
    return connection.get("portal/deposit/products");
  },
  getDepositProduct({ id }) {
    return connection.get(`bo/deposit/products/${id}`);
  },
  getTaxationCountries ({ taxType }) {
    return connection.get('bo/taxes/countries', {
      params: {
        taxType
      }
    })
  }
};
