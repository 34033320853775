export const loanStatuses = {
  inserted: "INSERTED",
  pending: "PENDING",
  signed: "SIGNED",
  deleted: "DELETED",
  matured: "MATURED",
  complete: "COMPLETE",
  active: "ACTIVE",
  delayed: "DELAYED",
  collector: "COLLECTOR",
  bankrupt: "BANKRUPT",
  bailiff: "BAILIFF",
  conkedOut: "CONKED_OUT",
  terminated: "TERMINATED",
  breached: "BREACHED",
  closed: "CLOSED",
  agreed: "AGREED",
  writtenOff: "WRITTEN-OFF"
};

export const authTypes = [
  "LoginPassword",
  "LoginIdCard",
  "LoginMobileId",
  "LoginSmartId"
];

export const allowedAuthTypes = JSON.parse(
  process.env.VUE_APP_ALLOWED_AUTH_TYPES ?? '""'
);
