import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import { toUpperCase } from "@/helpers";
import defaults from "lodash/defaults";
const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    classifiers: {},
    productTypes: {},
    creditIssuers: {}
  },

  getters: {
    classifierById: state => (classifier, id) =>
      (state.classifiers[toUpperCase(classifier)] &&
        state.classifiers[toUpperCase(classifier)][id]) ||
      {},
    classifierByName: state => (classifier, name) => {
      return (
        (state.classifiers[toUpperCase(classifier)] &&
          Object.values(state.classifiers[toUpperCase(classifier)]).find(
            classifier => classifier.name === name
          )) ||
        {}
      );
    },
    optionsFromClassifier: ({ classifiers }) => classifier =>
      Object.values(classifiers[toUpperCase(classifier)] ?? {}),
    optionsSourceOfIncome: ({ classifiers }) =>
      Object.values(classifiers[toUpperCase('sourceOfIncome')] ?? {})
  },

  actions: {
    async loadCountryClassifier({ commit, dispatch }) {
      const { data: countries } = await startLoading(
        dispatch,
        "classifiers:countries:fetch",
        () => api.getClassifiersCountry()
      );

      commit("SET_CLASSIFIER", { name: "COUNTRIES", classifiers: countries });
    },
    async loadCreditIssuers({ commit, dispatch }) {
      const { data: creditIssuers } = await startLoading(
        dispatch,
        "classifiers:creditIssuers:fetch",
        () => api.getCreditIssuers()
      )

      commit('SET_CREDIT_ISSUERS', { creditIssuers })
    },
    async loadTaxationCountries ({ commit, dispatch }, { taxType = 'WITHHOLD_TAX' } = {}) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:taxationCountries:fetch', () => api.getTaxationCountries({ taxType }))

      commit('SET_CLASSIFIER_TAX', { name: 'taxationCountries', classifiers })
    },
    async loadClassifiers({ commit, dispatch, rootState }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        "classifiers:fetch",
        () => api.getClassifiers({ lang: rootState.i18n.currentLocale })
      );

      Object.entries(classifiers).forEach(([name, value]) =>
        commit("SET_CLASSIFIER", { name, classifiers: value })
      );
    },
    async loadDepositProductTypes({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        "classifiers:deposits:productTypes:fetch",
        () => api.getDepositProductTypes()
      );

      commit("SET_CLASSIFIER", { name: "productTypes", classifiers });
    },
    async loadDepositContractClassifiers({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        "classifiers:deposit:contract:fetch",
        () => api.getDepositContractClassifiers()
      );

      commit("SET_CLASSIFIERS_V2", { classifiers });
    },
    async loadFactoringContractClassifiers({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        "classifiers:factoring:contract:fetch",
        () => api.getFactoringContractClassifiers()
      );

      commit("SET_CLASSIFIERS_V2", { classifiers });
    },
    async loadProductTypes ({ commit, dispatch }) {
      const { data: productTypes } = await startLoading(dispatch, 'classifiers:productTypes:fetch', () => api.getProductTypes())
      commit('SET_PRODUCT_TYPES', { productTypes })
    },
    async loadFactoringInvoicesClassifiers({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        "classifiers:factoring:invoices:fetch",
        () => api.getPortalImportedInvoiceStatusGroupClassifier()
      );

      commit("SET_CLASSIFIER", { name: "FACTORING_IMPORTED_INVOICE_STATUSES", classifiers });
    }
  },

  mutations: {
    SET_CLASSIFIER(state, { name, classifiers }) {
      state.classifiers = {
        ...state.classifiers,
        [toUpperCase(name)]: classifiers.reduce(
          (acc, current) => ({ ...acc, [current.id]: current }),
          {}
        )
      };
    },
    SET_CLASSIFIER_TAX (state, { name, classifiers }) {
      state.classifiers = {
        ...state.classifiers,
        [toUpperCase(name)]: classifiers
      }
    },
    SET_CLASSIFIERS_V2(state, { classifiers }) {
      state.classifiers = defaults(
        state.classifiers,
        classifiers.reduce(
          (acc, { name, values }) => ({
            ...acc,
            [toUpperCase(name)]: Object.entries(values).reduce(
              (res, [key, value]) => ({
                ...res,
                [key]: { name: key, id: key, human: value, code: key }
              }),
              {}
            )
          }),
          {}
        )
      );
    },
    SET_CREDIT_ISSUERS (state, { creditIssuers }) {
      state.creditIssuers = creditIssuers.reduce((acc, current) => {
        return { ...acc, [current.customerId]: current }
      }, {})
    },
    SET_PRODUCT_TYPES (state, { productTypes }) {
      state.productTypes = productTypes.reduce((acc, current) => {
        return { ...acc, [current.code]: current }
      }, {})
    },
  }
};
