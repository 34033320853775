export default function(el, { value, modifiers }, vnode) {
  const { permissions } = vnode.context.$store.state.auth;

  const modifier = (modifiers.each && "each") || "some";
  let isVisible = true;

  if (Array.isArray(value)) {
    isVisible = value[modifier](permission => permissions.isExist(permission));
  } else {
    isVisible = permissions.isExist(value);
  }

  if (!isVisible) {
    commentNode(el, vnode);
  }
}

function commentNode(el, vnode) {
  const comment = document.createComment(" ");

  Object.defineProperty(comment, "setAttribute", {
    value: () => undefined
  });

  vnode.text = " ";
  vnode.elm = comment;
  vnode.isComment = true;
  vnode.tag = undefined;

  vnode.data = vnode.data || {};
  vnode.data.directives = undefined;

  if (vnode.componentInstance) {
    // @ts-ignore
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
}
