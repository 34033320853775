import accounts from "./accounts";
import applications from "./applications";
import auth from "./auth";
import customers from "./customers";
import customFields from './customFields'
import loans from "./loans";
import messages from "./messages";
import passwordReset from "./passwordReset";
import products from "./products";
import resources from "./resources";
import service from "./service";
import settings from "./settings";
import signature from "./signature";
import system from "./system";
import depositProducts from "./depositProducts";
import depositContract from "./depositContract";
import relations from "./relations";
import payments from './payments';
import factoring from './factoring';
import factoringInvoices from './factoringInvoices';
import factoringThirdParties from '@/api/factoringThirdParties'

export default {
  ...accounts,
  ...applications,
  ...auth,
  ...customers,
  ...customFields,
  ...depositProducts,
  ...depositContract,
  ...loans,
  ...factoring,
  ...factoringInvoices,
  ...factoringThirdParties,
  ...messages,
  ...passwordReset,
  ...products,
  ...resources,
  ...service,
  ...settings,
  ...signature,
  ...system,
  ...relations,
  ...payments,
}
