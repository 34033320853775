import { connection, downloadFile } from "./connection";
import pickBy from "lodash/pickBy";
import qs from "qs";

export default {
  getLoans() {
    return connection.get("portal/loans/overview");
  },

  getLoan({ id }) {
    return connection.get(`portal/loans/${id}`);
  },

  getContractDetails({ id }) {
    return connection.get(`portal/loan/${id}`);
  },

  getLoanSchedule({ id, scheduleId }) {
    return connection.get(`portal/loan/${id}/schedule`, {
      params: {
        scheduleId
      }
    });
  },

  postOverdraftPayout({ overdraftId, paymentData }) {
    return connection.post(`portal/payments/outgoing/overdraft/${overdraftId}`, paymentData);
  },

  getOverdraftTerm({ id, termId }) {
    return connection.get(`portal/${id}/term`, {
      params: {
        termId
      }
    });
  },

  getLoanPayments({ loanId, filters }) {
    return connection.get(`portal/loans/${loanId}/payments`, {
      params: pickBy(filters)
    });
  },

  getLoanScheduleFile({ id }) {
    return downloadFile(`portal/loans/${id}/scheduleDownload`);
  },

  getLoanTransactions({ loanId, accountTypes }) {
    return connection.get(`portal/loans/${loanId}/transactions`, {
      params: {
        accountTypes
      },
      paramsSerializer: params => {
        return qs.stringify(params, { encode: false, arrayFormat: "repeat" });
      }
    });
  },

  putLoanScheduleAccept({ loanId }) {
    return connection.put(`portal/loans/${loanId}/extension/accept`);
  },

  postLoanScheduleExtend({ loanId, extensionDays }) {
    return connection.post(`portal/loans/${loanId}/extension/schedule`, {
      extensionDays
    });
  }
};
