import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import unionBy from "lodash/unionBy";
import numeral from 'numeral'

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    settings: null,
    boSettings: {},
    system: {},
    systemLogo: null,
    relations: [],
    allLanguages: [],
    customerCurrency: null,
    customerCurrencySymbol: null,
  },

  getters: {
    depositMode: state => JSON.parse(state.boSettings.depositMode ?? "false"),
    factoringMode: state => JSON.parse(state.boSettings.factoringMode ?? "false")
  },

  actions: {
    async loadSettings({ commit, dispatch }) {
      const response = await startLoading(dispatch, "settings:fetch", () =>
         api.getSettings()
      );
      commit("SET_SETTINGS", { settings: response.data });
    },

    async getAllLanguages({ commit }) {
      const response = await api.getAllLanguages()

      commit("SET_ALL_LANGUAGES", { allLanguages: response.data })
    },

    async getBackofficeSettings({ commit }) {
      const response = await api.getBackofficeSettings()

      commit("SET_BO_SETTINGS", { settings: response.data })
      commit("SET_CUSTOMER_CURRENCY", { currency: response.data.currency })
      commit("SET_CUSTOMER_CURRENCY_SYMBOL", { currencySymbol: response.data.currencySymbol })
    },

    async updateSettings({ commit, dispatch }, { data }) {
      const response = await startLoading(dispatch, "settings:save", () => {
        return api.putSettings({ data });
      });
      commit("UPDATE_SETTINGS", { settings: response.data });
    },
    async loadSystemSettings({ commit }) {
      const { data: system } = await api.getSystemSettings();

      commit("SET_SYSTEM_SETTINGS", { system });
    },
    async loadSystemLogo({ commit, dispatch }) {
      try {
        const response = await startLoading(
          dispatch,
          `settings:logo:fetch`,
          () => api.getSystemLogo()
        );
        if (response.status === 204) return;
        const filename =
          (response.headers["content-disposition"] || "").match(
            /filename="(.*)"/
          )[1] || "systemLogo";
        const file = new File([response.data], filename, {
          type: response.headers["content-type"]
        });
        const fileReader = new FileReader();
        fileReader.onload = e =>
          commit("SET_SYSTEM_LOGO", { logo: e.target.result });
        fileReader.readAsDataURL(file);
      } catch (e) {
        Promise.reject(e);
      }
    },
    async loadRelations({ commit, dispatch }) {
      const { data: relations } = await startLoading(
        dispatch,
        "settings:relations:fetch",
        () => api.getRelationList()
      );

      commit("SET_RELATIONS", { relations });
    },
    async createRelation({ commit, dispatch }, { relationData }) {
      const { data: relation } = await startLoading(
        dispatch,
        "settings:relations:create",
        () => api.postRelation({ relationData })
      );

      commit("ADD_RELATION", { relation });
    },
    async updateRelation({ commit, dispatch }, { relationId, relationData }) {
      const { data: relation } = await startLoading(
        dispatch,
        `settings:relation:${relationId}:save`,
        () => api.putRelation({ relationData, relationId })
      );

      commit("UPDATE_RELATION", { relation });
    },
    async deleteRelation({ commit, dispatch }, { relationId }) {
      await startLoading(
        dispatch,
        `settings:relation:${relationId}:delete`,
        () => api.deleteRelation({ relationId })
      );
      commit("DELETE_RELATION", { relationId });
    }
  },

  mutations: {
    SET_SETTINGS(state, { settings }) {
      state.settings = settings;
    },
    SET_BO_SETTINGS(state, { settings }) {
      state.boSettings = settings;
    },
    SET_CUSTOMER_CURRENCY(state, { currency }) {
      state.customerCurrency = currency;
    },
    SET_CUSTOMER_CURRENCY_SYMBOL(state, { currencySymbol }) {
      numeral.localeData().delimiters.thousands = ' '
      numeral.localeData().currency.symbol = currencySymbol
      state.customerCurrencySymbol = currencySymbol;
    },
    SET_ALL_LANGUAGES(state, { allLanguages }) {
      state.allLanguages = allLanguages;
    },
    UPDATE_SETTINGS(state, { settings }) {
      state.settings = {
        ...state.settings,
        ...settings
      };
    },
    SET_SYSTEM_SETTINGS(state, { system }) {
      state.system = system;
    },
    SET_SYSTEM_LOGO(state, { logo }) {
      state.systemLogo = logo;
    },
    SET_RELATIONS(state, { relations }) {
      state.relations = relations;
    },
    ADD_RELATION(state, { relation }) {
      state.relations.push(relation);
    },
    UPDATE_RELATION(state, { relation }) {
      state.relations = unionBy([relation], state.relations, "id");
    },
    DELETE_RELATION(state, { relationId }) {
      const idx = state.relations.findIndex(({ id }) => id === relationId);
      state.relations.splice(idx, 1);
    }
  }
};
