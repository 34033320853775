import Vue from "vue";
import moment from "moment";
import numeral from "numeral";
import store from "@/store/modules/settings"

const dateFormats = {
  en: "D-MMM-YYYY",
  et: "D.MM.YYYY",
  ru: "D.MM.YYYY"
};

Vue.filter("date", value => {
  if (!value) return value;
  return moment(value).format();
});

Vue.filter("datetime", value => {
  if (!value) return value;
  return moment(value).format(dateFormats.en + " HH:mm");
});

numeral.localeData().delimiters.thousands = " ";

const currencySymbols = {
  EUR: "€",
  USD: "$",
  RUB: "₽"
};

Vue.filter("money", (value, currency) => {
  if (!value && value !== 0) return value;
  const { customerCurrency } = store.state;
  const symbol = store.state.customerCurrencySymbol || currencySymbols[customerCurrency] || currencySymbols.EUR;
  return `${numeral(value).format("0,0.00")} ${symbol}`;
});

Vue.filter("percent", value => {
  if (!value && value !== 0) return value;
  return numeral(value).format("0.00 %");
});

Vue.filter("uppercase", value => {
  if (!value) return value;
  return String(value).toUpperCase();
});
