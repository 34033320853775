import { connection, downloadFile } from '@/api/connection'
import pickBy from 'lodash/pickBy'

export default {
  getAccountPayments({ accountId, filters }) {
    return connection.get(`portal/payments/outgoing/account/${accountId}`, {
      params: pickBy(filters)
    });
  },
  getPaymentData({ paymentId, type }) {
    return connection.get(`/portal/payments/outgoing/${paymentId}`, {
      params: {
        type
      }
    });
  },
  getPaymentDataPDF({ id }) {
    return downloadFile(`portal/payments/outgoing/payment-order/${id}`, {
      params: {
        type: "PDF"
      }
    });
  },
  postAccountPayment({ paymentData }) {
    return connection.post('portal/payments/outgoing/prepayment', paymentData);
  },
  putAccountPayment( id, status ) {
    return connection.put(`portal/payments/outgoing/${id}/status?status=${status}`);
  }
}
