import { connection } from '@/api/connection'
import { plainToInstance } from 'class-transformer'
import { CImportedInvoice } from '@/models/CImportedInvoice'
import { createBlobFromObject } from '@/helpers'

export default {
  getFactoringContractClassifiers: () => connection.get("portal/factoring/classifiers"),
  getFactoringContracts: () => connection.get('portal/factoring/contracts/'),
  getFactoringProducts: () => connection.get('portal/factoring/products'),
  getFactoringContractOverview: ({ contractId, length }) => connection.get(`portal/factoring/screen/${contractId}/${length}`),
  postImportedInvoice: ({ invoiceData, files = [] }) => {
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append("files", file);
    });

    formData.append("json", createBlobFromObject(invoiceData));

    return connection.post('portal/factoring/upload/invoice', formData)
  },
  postFactoringThirdParty: ({ thirdPartyData, contractId }) => connection.post(`portal/factoring/contracts/${contractId}/third-parties`, thirdPartyData)
}
