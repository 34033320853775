import { downloadFile, connection } from "./connection";

const controllerRoot = "portal/resources";

export default {
  getResourceFile({ id }) {
    return downloadFile(`${controllerRoot}/${id}/download`);
  },
  postResource({ model, modelId, files, comment }) {
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append("file", file);
    });
    formData.append("resource", new Blob([JSON.stringify({ comment })]));
    return connection.post(`${controllerRoot}`, formData, {
      params: {
        model,
        model_id: modelId
      }
    });
  },
  getLoanResources({ loanId } = {}) {
    return connection.get(`${controllerRoot}/loan/${loanId}`);
  }
};
