import { connection } from "./connection";

export default {
  async getSettings() {
    return await connection.get("portal/settings");
  },

  async getBackofficeSettings() {
    return await connection.get("bo/settings");
  },

  async getAllLanguages() {
    return await connection.get("portal/available-languages");
  },

  putSettings({ data }) {
    return connection.put("portal/settings", data);
  },

  getSystemLogo() {
    return connection.get(`system/logo`, { responseType: "blob" });
  }
};
