import { connection } from "./connection";
import pickBy from 'lodash/pickBy'
import isNumber from 'lodash/isNumber'

export default {
  getClassifiers({ lang }) {
    return connection.get("portal/classifiers", { params: { lang: lang } });
  },
  getLoanInitialOffer(data) {
    return connection.get("portal/loanInitialOfferInfo", {
      params: pickBy(data, isNumber)
    });
  },
  getClassifiersCountry: () => connection.get("bo/classifiers/country"),
  getCreditIssuers: () => connection.get('bo/credit-issuers')
};
