import api from "@/api";
import { createActionHelpers } from "vuex-loading";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    resetError: null
  },

  actions: {
    clearError({ commit }) {
      commit("SET_ERROR", { message: null });
    },
    async sendRequestResetToken({ dispatch }, login) {
      await startLoading(dispatch, "reset:token:save", () =>
        api.postRequestResetToken(login)
      );
    },

    async sendResetPassword({ dispatch, commit }, { newPassword, token }) {
      await dispatch("clearError");
      try {
        await startLoading(dispatch, "reset:password:save", () =>
          api.postResetPassword({ newPassword, token })
        );
      } catch (e) {
        commit("SET_ERROR", { message: e.response.data });
        return Promise.reject(e.response.data);
      }
    }
  },

  mutations: {
    SET_ERROR(state, { message }) {
      state.resetError = message;
    }
  }
};
