import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { allowedAuthTypes } from "@/const";
import { PERMISSION_TYPES } from "@/modules/permissions/const";

Vue.use(Router);

export const authRoutes = [
  {
    path: "/login/password",
    name: "LoginPassword",
    props: route => ({ redirect: route.query.redirect }),
    component: () => import("@/views/auth/LoginPage")
  },
  {
    path: "/login/mobile-id",
    name: "LoginMobileId",
    props: route => ({ redirect: route.query.redirect }),
    component: () => import("@/views/auth/LoginMobileIdPage")
  },
  {
    path: "/login/smart-id",
    name: "LoginSmartId",
    props: route => ({ redirect: route.query.redirect }),
    component: () => import("@/views/auth/LoginSmartIdPage")
  },
  {
    path: "/login/id-card",
    name: "LoginIdCard",
    props: route => ({ redirect: route.query.redirect }),
    component: () => import("@/views/auth/LoginIdCardPage")
  }
].filter(
  ({ name }) => !allowedAuthTypes.length || allowedAuthTypes.includes(name)
);

const router = new Router({
  mode: "history",
  linkActiveClass: "_active",
  scrollBehavior: () => ({ y: 0 }),

  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/home/HomePage"),
      alias: '/home'
    },
    {
      path: "/accounts/:id?",
      name: "Accounts",
      component: () => import("@/views/accounts/AccountsPage"),
      props: true,
      children: [
        {
          path: "payments/new",
          name: "AccountNewPayment",
          component: () => import("@/views/accounts/AccountPaymentNewPage"),
          props: (route) => ({ id: Number(route.params.id), payment: route.params.payment })
        },
        {
          path: "payments/:type/:paymentId",
          name: "PaymentPage",
          component: () => import("@/views/accounts/AccountPaymentPage"),
          props: true
        }
      ]
    },
    {
      path: "/messages",
      name: "Threads",
      component: () => import("@/views/messages/ThreadsPage")
    },
    {
      path: "/messages/new",
      name: "MessageNew",
      meta: {
        visible: [PERMISSION_TYPES.admin, PERMISSION_TYPES.applyAndSign]
      },
      props: route => ({ threadId: route.query.threadId }),
      component: () => import("@/views/messages/MessageNewPage")
    },
    {
      path: "/messages/:id",
      name: "Thread",
      props: true,
      component: () => import("@/views/messages/ThreadPage")
    },
    {
      path: "/home/loans/new",
      name: "ApplicationNew",
      props: route => ({ step: Number.parseInt(route.query.step) || 0 }),
      meta: {
        visible: [PERMISSION_TYPES.admin, PERMISSION_TYPES.applyAndSign]
      },
      component: () => import("@/views/applications/ApplicationNewPage")
    },
    {
      path: "/home/deposit/new",
      name: "DepositNew",
      props: route => ({ step: Number.parseInt(route.query.step) || 0 }),
      meta: {
        visible: [PERMISSION_TYPES.admin, PERMISSION_TYPES.applyAndSign]
      },
      component: () => import("@/views/deposits/DepositNewPage")
    },
    {
      path: "/home/factoring/new",
      name: "FactoringNew",
      props: route => ({ step: Number.parseInt(route.query.step) || 0 }),
      meta: {
        visible: [PERMISSION_TYPES.admin, PERMISSION_TYPES.applyAndSign]
      },
      component: () => import("@/views/applications/FactoringNewPage")
    },
    {
      path: "/deposits/:id?",
      name: "Deposits",
      props: true,
      component: () => import("@/views/deposits/DepositPage"),
      redirect: { name: "DepositOverview" },
      children: [
        {
          path: "overview",
          name: "DepositOverview",
          props: true,
          component: () => import("@/views/deposits/DepositOverviewPage")
        },
        {
          path: "payments",
          name: "DepositPayments",
          props: true,
          component: () => import("@/views/deposits/DepositPaymentsPage")
        }
      ]
    },
    {
      path: "/factoring/:id?",
      name: "Factoring",
      props: true,
      component: () => import("@/views/factoring/FactoringPage"),
      redirect: { name: "FactoringOverview" },
      children: [
        {
          path: "overview",
          name: "FactoringOverview",
          props: true,
          component: () => import("@/views/factoring/FactoringOverviewPage")
        },
        {
          path: "invoices",
          name: "FactoringInvoices",
          props: true,
          component: () => import("@/views/factoring/FactoringInvoicesPage")
        },
        {
          path: "invoices/new",
          name: "FactoringInvoiceNew",
          props: true,
          component: () => import("@/views/factoring/FactoringInvoiceNewPage")
        },
        {
          path: "third-parties",
          name: "FactoringThirdParties",
          props: true,
          component: () => import("@/views/factoring/FactoringThirdPartiesPage")
        },
        {
          path: "third-parties/new",
          name: "FactoringThirdPartyNew",
          props: true,
          component: () => import("@/views/factoring/FactoringThirdPartyNewPage")
        }
      ]
    },
    {
      path: "/loans/:id?",
      name: "Loans",
      props: true,
      component: () => import("@/views/loans/LoansPage"),
      redirect: { name: "LoanOverview" },
      children: [
        {
          path: "overview",
          name: "LoanOverview",
          props: true,
          component: () => import("@/views/loans/LoanOverviewPage")
        },
        {
          path: "payments/new",
          name: "LoanNewPayment",
          component: () => import("@/views/loans/LoanPaymentNewPage"),
          props: (route) => ({ id: Number(route.params.id) })
        },
        {
          path: "schedule",
          name: "LoanSchedule",
          props: true,
          component: () => import("@/views/loans/LoanSchedulePage")
        },
        {
          path: "payouts",
          name: "LoanPayouts",
          props: true,
          component: () => import("@/views/loans/LoanPayoutsPage")
        },
        {
          path: "extend",
          name: "LoanExtend",
          props: true,
          component: () => import("@/views/loans/LoanExtendPage")
        }
      ]
    },
    {
      path: "/settings",
      name: "Settings",
      component: () => import("@/views/settings/SettingsPage")
    },
    ...authRoutes,
    {
      path: "/login",
      name: "Login",
      redirect: authRoutes[0].path
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("@/views/auth/LogoutPage")
    },
    {
      path: "/auth/reset",
      name: "Reset",
      component: () => import("@/views/reset/ResetRequestPage")
    },
    {
      path: "/auth/reset/confirm",
      name: "ResetConfirm",
      component: () => import("@/views/reset/ResetConfirmPage")
    },
    {
      path: "/auth/reset/success",
      name: "ResetSuccess",
      component: () => import("@/views/reset/ResetSuccessPage")
    },
    {
      path: "/auth/reset/:token",
      props: true,
      component: () => import("@/views/reset/ResetNewPasswordPage")
    }
  ]
});

const { state, dispatch } = store;

router.beforeEach((to, from, next) => {
  if (state.visibleMenu) dispatch("toggleMenu");

  const isLoggedIn = state.auth.isLoggedIn;
  const isLoginPage = /^\/login/.test(to.path);
  const isLogoutPage = /^\/logout/.test(to.path);
  const isResetPage = /^\/auth\/reset/.test(to.path);

  if (isLoggedIn && isLoginPage) {
    next("/");
  } else if (!isLoggedIn && !isLoginPage && !isResetPage && !isLogoutPage) {
    next({ name: "Login", query: { redirect: to.path } });
  } else {
    next();
  }
});

export default router;
