import { connection } from "./connection";
import omit from "lodash/omit";
import { createBlobFromObject } from '@/helpers'

export default {
  postApplication({ applicationData, files }) {
    applicationData.periodUnitTypeId = 3
    applicationData.monthlyPayment = 0
    const formData = new FormData();
    formData.append(
      "applicationData",
      createBlobFromObject(applicationData)
    );
    for (let file of files) {
      formData.append("file", file);
    }
    return connection.post(`portal/ca`, formData);
  }
};
