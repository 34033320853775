import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import orderBy from "lodash/orderBy";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export const CONTRACT_STATUS_RANK = {
  INSERTED: 1,
  DELAYED: 2,
  ACTIVE: 3
};

export default {
  namespaced: true,

  state: {
    loans: [],
    loan: null,
    loanPayments: [],
    totalPayments: 0,
    loanResources: [],
    loanTransactions: [],
    loanSchedule: null
  },

  getters: {
    loanStatusIcons: () => ({ id }) => {
      const iconMap = {
        INSERTED: "pause_circle_outline",
        ACTIVE: "timelapse",
        IN_DEBT: "warning",
        DELAYED: "warning",
        SIGNED: "edit",
        TERMINATED: "cancel"
      };

      return iconMap[id] || "pending";
    },
    loanStatusColor: () => ({ code, name }) => {
      const iconMap = {
        IN_DEBT: "_text-error",
        DELAYED: "_text-error"
      };

      return iconMap[code || name];
    },
    loanInvoiceStatusColor: () => invoiceStatus => {
      const colorMap = {
        INSERTED: "_purple",
        REQUESTED: "_purple",
        PENDING: "_purple",
        PAID_ON_TIME: "_green",
        PAID_LATE: "_orange",
        IN_DEBT: "_red",
        PROCESSED: "_green"
      };
      return colorMap[invoiceStatus.id] || "_purple";
    },
    loanInvoiceStatusIcon: () => invoiceStatus => {
      const colorMap = {
        INSERTED: "pause_circle_outline",
        ACTIVE: "timelapse",
        PAID_LATE: "warning",
        SIGNED: "edit",
        TERMINATED: "cancel",
        PENDING: "pending_invoice",
        PAID_ON_TIME: "clock-check",
        PROCESSED: "check",
        IN_DEBT: "warning"
      };
      return colorMap[invoiceStatus.id] || "pending_invoice";
    }
  },

  actions: {
    /** Plural loans */
    async loadLoans({ dispatch, commit, rootGetters }) {
      commit("SET_LOANS", { loans: [] });
      const { data } = await startLoading(dispatch, "loans:fetch", () =>
        api.getLoans()
      );
      const loans = orderBy(
        data,
        [
          value =>
            CONTRACT_STATUS_RANK[value.status.code] ||
            rootGetters["classifiers/classifierByName"](
              "loanStatuses",
              value.status.code
            ).id + 10,
          value => value.updatedAt
        ],
        ["asc", "desc"]
      );
      commit("SET_LOANS", { loans });
    },
    resetLoans({ commit }) {
      commit("RESET_LOANS");
    },
    /** --- */
    /** Single loan */
    async loadLoan({ dispatch, commit }, { id }) {
      commit("SET_LOAN", { loan: null });
      const response = await startLoading(dispatch, `loan:${id}:fetch`, () => {
        return api.getContractDetails({ id });
      });
      commit("SET_LOAN", { loan: response.data });
    },
    resetLoan({ commit }) {
      commit("RESET_LOAN");
    },
    /** --- */
    async loadLoanPayments({ dispatch, commit }, { loanId, limit }) {
      commit("SET_LOAN_PAYMENTS", { payments: [], totalPayments: 0 });
      const response = await startLoading(
        dispatch,
        `loan:${loanId}:payments:fetch`,
        () => {
          return api.getLoanPayments({
            loanId,
            filters: { page: 0, size: limit, sort: "paymentDate,desc" }
          });
        }
      );
      commit("SET_LOAN_PAYMENTS", {
        payments: response.data.content,
        totalPayments: response.data.totalElements
      });
    },

    async loadLoanScheduleFile({ dispatch }, { id }) {
      await startLoading(dispatch, `loan:${id}:schedule:download`, () => {
        return api.getLoanScheduleFile({ id });
      });
    },
    async saveLoanResource({ dispatch, commit }, { loanId, files, comment }) {
      const { data: resource } = await startLoading(
        dispatch,
        `resource:save`,
        () =>
          api.postResource({ model: "LOAN", modelId: loanId, files, comment })
      );
      commit("SET_LOAN_RESOURCE", { resource });
    },
    async loadLoanResources({ dispatch, commit }, { loanId }) {
      const { data: resources } = await startLoading(
        dispatch,
        "resources:fetch",
        () => api.getLoanResources({ loanId })
      );
      commit("SET_LOAN_RESOURCES", { resources });
    },
    async loadLoanTransactions({ dispatch, commit }, { loanId, accountTypes }) {
      const {
        data: { content: transactions }
      } = await startLoading(dispatch, `loan:transactions:fetch`, () =>
        api.getLoanTransactions({ loanId, accountTypes })
      );

      commit("SET_LOAN_TRANSACTIONS", { transactions });
    },
    async saveLoanApplyScheduleExtension(
      { dispatch, commit },
      { loanId, extensionDays }
    ) {
      const { data: loan } = await startLoading(
        dispatch,
        "loan:schedule:extend:save",
        () => api.postLoanScheduleExtend({ loanId, extensionDays })
      );

      commit("SET_LOAN", { loan });
    },
    async saveLoanAcceptScheduleExtension({ dispatch, commit }, { loanId }) {
      const { data: loan } = await startLoading(
        dispatch,
        "loan:schedule:extend:save",
        () => api.putLoanScheduleAccept({ loanId })
      );

      commit("SET_LOAN", { loan });
    },
    async loadLoanSchedule({ dispatch, commit }, { id, scheduleId, isOverdraft = false }) {
      const action = isOverdraft ? api.getOverdraftTerm : api.getLoanSchedule
      const { data: schedule } = await startLoading(dispatch, `loan:${id}:schedule:fetch`, () => action({ id, scheduleId }));
      commit("SET_LOAN_SCHEDULE", { schedule });
    },
    saveLoanPayment({ dispatch, commit }, { id, paymentData }) {
      return startLoading(dispatch, `loan:${id}:payment:save`, () => api.postOverdraftPayout({ overdraftId: id, paymentData }))
    }
  },

  mutations: {
    SET_LOANS(state, { loans }) {
      state.loans = loans;
    },
    RESET_LOANS(state) {
      state.loans = [];
    },

    SET_LOAN(state, { loan }) {
      state.loan = loan;
    },
    RESET_LOAN(state) {
      state.loan = {};
    },

    SET_LOAN_PAYMENTS(state, { payments, totalPayments }) {
      state.loanPayments = payments;
      state.totalPayments = totalPayments;
    },
    SET_LOAN_RESOURCE(state, { resource }) {
      state.loanResources.push(resource);
    },
    SET_LOAN_RESOURCES(state, { resources }) {
      state.loanResources = resources;
    },
    SET_LOAN_TRANSACTIONS(state, { transactions }) {
      state.loanTransactions = transactions;
    },
    SET_LOAN_SCHEDULE(state, { schedule }) {
      state.loanSchedule = schedule;
    },
  }
};
