import api from "@/api";
import { createActionHelpers } from "vuex-loading";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    offerInfo: {}
  },

  actions: {
    async createApplication({ commit, dispatch }, { applicationData, files }) {
      await startLoading(dispatch, "application:save", () => {
        return api.postApplication({ applicationData, files });
      });
    },
    async loadOfferInfo({ commit, dispatch }, data) {
      commit("RESET_OFFER_INFO");

      const response = await startLoading(
        dispatch,
        "application:offer:info:fetch",
        () => api.getLoanInitialOffer(data)
      );

      commit("SET_OFFER_INFO", { offer: response.data });
    },
    resetOfferInfo({ commit }) {
      commit("RESET_OFFER_INFO");
    }
  },

  mutations: {
    SET_OFFER_INFO(state, { offer }) {
      state.offerInfo = offer;
    },
    RESET_OFFER_INFO(state) {
      state.offerInfo = {};
    }
  }
};
