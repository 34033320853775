import { createActionHelpers } from "vuex-loading";
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: "loading" });

const state = {
  contracts: [],
  contractOverview: null,
  importedInvoices: [],
  contractThirdParties: [],
  factoringResources: [],
}

const mutations = {
  SET_FACTORING_CONTRACTS(state, { contracts }) {
    state.contracts = contracts
  },
  SET_FACTORING_CONTRACT(state, { contract }) {
    state.contractOverview = contract
  },
  SET_FACTORING_INVOICES(state, { invoices }) {
    state.importedInvoices = invoices
  },
  UPDATE_FACTORING_INVOICES(state, { invoice }) {
    state.importedInvoices.push(invoice)
  },
  SET_FACTORING_THIRD_PARTIES(state, { thirdParties }) {
    state.contractThirdParties = thirdParties
  },
  UPDATE_FACTORING_THIRD_PARTIES(state, { thirdParty }) {
    state.contractThirdParties.push(thirdParty)
  },
  SET_FACTORING_RESOURCE(state, { resource }) {
    state.factoringResources.push(resource);
  },
  SET_FACTORING_RESOURCES(state, { resources }) {
    state.factoringResources = resources;
  },
}

const actions = {
  async loadFactoringContracts({ commit, dispatch }) {
    const { data: contracts } = await startLoading(dispatch, 'factoring:contracts:fetch', () => api.getFactoringContracts())

    commit('SET_FACTORING_CONTRACTS', { contracts })
  },
  async loadFactoringContract({ commit, dispatch }, { contractId, length = 30 }) {
    const { data: contract } = await startLoading(dispatch, 'factoring:contract:fetch', () => api.getFactoringContractOverview({ contractId, length }))

    commit('SET_FACTORING_CONTRACT', { contract })
  },

  async loadFactoringInvoices({ commit, dispatch }, { contractId, status, history }) {
    const { data: invoices } = await startLoading(
      dispatch,
      'factoring:contract:invoices:fetch',
      () => api.getFactoringInvoices({ contractId, status, history }))

    commit('SET_FACTORING_INVOICES', { invoices })
  },
  async saveFactoringResource({ dispatch, commit }, { loanId, files, comment }) {
    const { data: resource } = await startLoading(
      dispatch,
      `resource:save`,
      () =>
        api.postResource({ model: "LOAN", modelId: loanId, files, comment })
    );

    commit("SET_FACTORING_RESOURCE", { resource });
  },
  async loadFactoringThirdParties({ commit, dispatch }, { contractId }) {
    const { data: thirdParties } = await startLoading(
      dispatch,
      'factoring:contract:thirdParties:fetch',
      () => api.getFactoringThirdParties({ contractId }))

    commit('SET_FACTORING_THIRD_PARTIES', { thirdParties })
  },
  async loadFactoringResources({ dispatch, commit }, { loanId }) {
    const { data: resources } = await startLoading(
      dispatch,
      "resources:fetch",
      () => api.getLoanResources({ loanId })
    );
    commit("SET_FACTORING_RESOURCES", { resources });
  },
  async saveFactoringImportedInvoice ({ commit, dispatch }, { invoiceData, files }) {
    const { data: invoice } = await startLoading(dispatch, 'contract:invoice:save', () => api.postImportedInvoice({ invoiceData, files }))

    commit('UPDATE_FACTORING_INVOICES', { invoice })
  },
  async saveFactoringThirdParty ({ commit, dispatch }, { thirdPartyData, contractId }) {
    const { data: thirdParty } = await startLoading(dispatch, 'contract:third-party:save', () => api.postFactoringThirdParty({ thirdPartyData, contractId }))

    commit('UPDATE_FACTORING_THIRD_PARTIES', { thirdParty })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
