import Vue from "vue";
import i18next from "i18next";
import VueI18Next from "@panter/vue-i18next";
import moment from "moment";

Vue.use(VueI18Next);

const locale = localStorage.getItem("locale") || "et";

const localisation = i18next;

localisation.init({
  lng: locale
});

const localize = lang => {
  moment.locale(lang);
  moment.defaultFormat = 'L';
};

i18next.on("languageChanged", lng => localize(lng));

const i18n = new VueI18Next(localisation);

export default i18n;
