import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import { PermissionsClass } from "@/modules/permissions/PermissionsClass";

const { startLoading, endLoading } = createActionHelpers({
  moduleName: "loading"
});

export default {
  namespaced: true,

  state: {
    signPossibleFinora: process.env.VUE_APP_SIGN_POSSIBLE_FINORA === "true",
    lastRequestTimestamp: null,
    authError: null,
    isLoggedIn: !!sessionStorage.getItem("token"),
    user: JSON.parse(sessionStorage.getItem("user")),
    mobileIdData: null,
    smartIdData: null,
    isIdCardAuth: sessionStorage.getItem("isIdCardAuth") === "true",
    isMobileIdAuth: sessionStorage.getItem("isMobileIdAuth") === "true",
    isSmartIdAuth: sessionStorage.getItem("isSmartIdAuth") === "true",
    permissions: new PermissionsClass(
      JSON.parse(atob(sessionStorage.getItem("permissions") ?? "") || "[]")
    )
  },

  getters: {
    authTypes: ({ isIdCardAuth, isMobileIdAuth, isSmartIdAuth }) => ({
      isIdCardAuth,
      isMobileIdAuth,
      isSmartIdAuth
    })
  },

  actions: {
    updateLastRequestTime({ commit }) {
      commit("SET_LAST_REQUEST_TIME", { time: Date.now() });
    },

    async updateSession() {
      try {
        const {
          data: { token = null }
        } = await api.postRefreshSession();
        sessionStorage.setItem("token", token);
      } catch (e) {}
    },

    updateSessionIfNeeded(
      { commit, dispatch, state },
      { time = Date.now() } = {}
    ) {
      if (!state.isLoggedIn) return;
      const lastSessionRenewal = sessionStorage.getItem(
        "lastSessionRenewal",
        time
      );
      if (lastSessionRenewal) {
        const elapsedSeconds = (time - lastSessionRenewal) / 1000 ?? 0;
        if (elapsedSeconds > 300) {
          dispatch("updateSession");
          sessionStorage.setItem("lastSessionRenewal", time);
        }
      }
    },

    clearError({ commit }) {
      commit("SET_AUTH_ERROR", { message: null });
    },

    async login({ dispatch, commit }, { userData }) {
      commit("SET_AUTH_ERROR", { message: null });
      try {
        const response = await startLoading(dispatch, "auth:login", () =>
          api.postAuth({ userData })
        );
        dispatch("loginSuccess", { data: response.data });
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
      }
    },

    async loginReferred({ dispatch, commit }, { referredId }) {
      commit("SET_AUTH_ERROR", { message: null });
      try {
        const response = await startLoading(dispatch, "auth:login", () =>
          api.getLoginReferred({ referredId })
        );
        dispatch("loginSuccess", { data: response.data });
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
      }
    },

    async mobileIdLogin({ dispatch, commit }, { phone, idCode }) {
      commit("SET_AUTH_ERROR", { message: null });
      commit("SET_MOBILE_ID_DATA", { data: null });
      try {
        startLoading(dispatch, "auth:mobileId");
        const response = await api.getMobileIdAuth({ phone, idCode });
        commit("SET_MOBILE_ID_DATA", response);
        setTimeout(() => {
          dispatch("mobileIdPoll");
        }, 2000);
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
      }
    },

    async mobileIdPoll({ state, dispatch, commit }) {
      try {
        const response = await api.getMobileIdPoll({ ...state.mobileIdData });
        if (response.status === 202) {
          setTimeout(() => {
            dispatch("mobileIdPoll");
          }, 2000);
        } else {
          commit("SET_MOBILE_ID_DATA", { data: null });
          commit("SET_MOBILE_ID_AUTH", { value: true });
          dispatch("loginSuccess", { data: response.data });
          endLoading(dispatch, "auth:mobileId");
        }
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
        commit("SET_MOBILE_ID_DATA", { data: null });
        endLoading(dispatch, "auth:mobileId");
      }
    },

    async smartIdLogin({ dispatch, commit }, { idCode, countryCode }) {
      commit("SET_AUTH_ERROR", { message: null });
      commit("SET_SMART_ID_DATA", { data: null });
      try {
        startLoading(dispatch, "auth:smartId");
        const response = await api.getSmartIdAuth({ idCode, countryCode });
        commit("SET_SMART_ID_DATA", response);
        setTimeout(() => {
          dispatch("smartIdPoll");
        }, 2000);
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
      }
    },

    async smartIdPoll({ state, dispatch, commit }) {
      try {
        const response = await api.getSmartIdPoll({ ...state.smartIdData });
        if (response.status === 202) {
          setTimeout(() => {
            dispatch("smartIdPoll");
          }, 2000);
        } else {
          commit("SET_SMART_ID_DATA", { data: null });
          commit("SET_SMART_ID_AUTH", { value: true });
          dispatch("loginSuccess", { data: response.data });
          endLoading(dispatch, "auth:smartId");
        }
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
        commit("SET_SMART_ID_DATA", { data: null });
        endLoading(dispatch, "auth:smartId");
      }
    },

    async idCardLogin({ dispatch, commit }) {
      commit("SET_AUTH_ERROR", { message: null });
      try {
        commit("SET_ID_CARD_AUTH", { value: true });
        const response = await startLoading(dispatch, "auth:idCard", () =>
          api.getIdCardAuth()
        );
        dispatch("loginSuccess", { data: response.data });
      } catch (e) {
        commit("SET_AUTH_ERROR", e.data);
      }
    },

    loginSuccess({ state, commit, dispatch }, { data }) {
      commit("LOGIN_SUCCESS", data);
    },

    async logout({ commit, dispatch }, { expireSession = true } = {}) {
      try {
        if (expireSession) {
          await api.postExpireSession();
        }
        commit("LOGOUT");
      } catch (e) {}
    }
  },

  mutations: {
    SET_LAST_REQUEST_TIME(state, { time }) {
      state.lastRequestTimestamp = time;
    },

    LOGIN_SUCCESS(
      state,
      {
        token,
        id,
        firstName,
        lastName,
        refreshToken,
        permissions,
        customerName
      }
    ) {
      state.isLoggedIn = true;
      state.user = { id, firstName, lastName, customerName };
      state.permissions = new PermissionsClass(permissions);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("user", JSON.stringify(state.user));
      sessionStorage.setItem("lastSessionRenewal", Date.now());
      sessionStorage.setItem("isIdCardAuth", state.isIdCardAuth);
      sessionStorage.setItem("isMobileIdAuth", state.isMobileIdAuth);
      sessionStorage.setItem("isSmartIdAuth", state.isSmartIdAuth);
      sessionStorage.setItem("permissions", btoa(JSON.stringify(permissions)));
    },

    SET_MOBILE_ID_DATA(state, { data }) {
      state.mobileIdData = data;
    },

    SET_SMART_ID_DATA(state, { data }) {
      state.smartIdData = data;
    },

    SET_MOBILE_ID_AUTH(state, { value }) {
      state.isMobileIdAuth = value;
    },

    SET_SMART_ID_AUTH(state, { value }) {
      state.isSmartIdAuth = value;
    },

    SET_ID_CARD_AUTH(state, { value }) {
      state.isIdCardAuth = value;
    },

    SET_AUTH_ERROR(state, { message }) {
      state.authError = message;
    },

    LOGOUT(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.isSmartIdAuth = false;
      state.isMobileIdAuth = false;
      state.isIdCardAuth = false;
      sessionStorage.clear();
    }
  }
};
