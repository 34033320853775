import { connection } from "@/api/connection";

export default {
  putMobileIdSign({ documentId, signerData }) {
    return connection.put(`portal/signature/mobile/${documentId}`, signerData);
  },
  putMobileIdSignPayout({ documentId, signerData }) {
    return connection.put(`/portal/payments/outgoing/signature/mobile/${documentId}`, signerData);
  },
  getMobileIdSignStatusWithDocId({ sessionCode, documentId }) {
    // return connection.get(`portal/signature/mobile/status/${sessionCode}`);
    return connection.get(`portal/payments/outgoing/mobile/status/${sessionCode}/${documentId}`);
  },
  getMobileIdSignStatus({ sessionCode }) {
    return connection.get(`portal/signature/mobile/status/${sessionCode}`);
  },
  putSmartIdSign({ documentId, signerData }) {
    return connection.put(`portal/signature/smart/${documentId}`, signerData);
  },
  putSmartIdSignPayout({ documentId, signerData }) {
    return connection.put(`portal/payments/outgoing/signature/smart/${documentId}`, signerData);
  },
  getSmartIdSignStatusWithDocId({ sessionCode, documentId }) {
    // return connection.get(`portal/signature/smart/status/${sessionCode}`);
    return connection.get(`portal/payments/outgoing/smart/status/${sessionCode}/${documentId}`);
  },
  getSmartIdSignStatus({ sessionCode }) {
    return connection.get(`portal/signature/smart/status/${sessionCode}`);
  },
  getHashToSignWithIdCard({ documentId, request }) {
    return connection.post(`/portal/signature/id-card/${documentId}`, request);
  },
  getHashToSignWithIdCardPayout({ documentId, request }) {
    return connection.post(`portal/payments/outgoing/signature/id-card/${documentId}`, request);
  },
  addIdCardSignature({ sessionCode, request }) {
    return connection.post(
        `/portal/signature/id-card/signature/${sessionCode}`,
        request
    );
  },
  addIdCardSignaturePayout({ documentId, sessionCode, request }) {
    return connection.post(
        `/portal/payments/outgoing/signature/id-card/${documentId}/signature/${sessionCode}`,
        request
    );
  }
};
