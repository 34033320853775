import { connection } from "@/api/connection";
import pickBy from "lodash/pickBy";

export default {
  getAccounts: () => connection.get('portal/accounts'),
  getAccountTransactions({ accountId, filters }) {
    return connection.get(`portal/${accountId}/transactions`, {
      params: pickBy(filters)
    });
  }
};
