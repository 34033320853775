import Vue from 'vue'
import Vuex from 'vuex'

import accounts from "./modules/accounts";
import applications from "./modules/applications";
import auth from "./modules/auth";
import classifiers from "./modules/classifiers";
import customer from "./modules/customer";
import customFields from './modules/customFields';
import deposits from "./modules/deposits";
import i18n from "./modules/i18n";
import loans from "./modules/loans";
import messages from "./modules/messages";
import passwordReset from "./modules/passwordReset";
import products from "./modules/products";
import resources from "./modules/resources";
import settings from "./modules/settings";
import signature from "./modules/signature";
import payments from './modules/payments';
import factoring from './modules/factoring';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    accounts,
    applications,
    auth,
    classifiers,
    customer,
    customFields,
    deposits,
    factoring,
    i18n,
    loans,
    messages,
    passwordReset,
    payments,
    products,
    resources,
    settings,
    signature
  },

  state: {
    visibleMenu: false
  },

  actions: {
    toggleMenu({ state, commit }) {
      commit("SET_VISIBLE_MENU", { visibleMenu: !state.visibleMenu });
    }
  },

  mutations: {
    SET_VISIBLE_MENU(state, { visibleMenu }) {
      state.visibleMenu = visibleMenu;
    }
  },
});
