import { connection } from "./connection";

const controllerRoot = "portal/password-reset";

export default {
  postResetPassword({ newPassword, token }) {
    return connection.post(`${controllerRoot}/reset`, { newPassword, token });
  },
  postRequestResetToken(login) {
    return connection.post(`${controllerRoot}/request`, { login });
  }
};
