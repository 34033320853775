import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

const MUTATION_TYPES = {
  setCustomField: 'SET_CUSTOM_FIELD',
  setCustomFields: 'SET_CUSTOM_FIELDS',
  addCustomField: 'ADD_CUSTOM_FIELD'
}

const state = {
  customFields: [],
  customField: null
}

const mutations = {
  [MUTATION_TYPES.setCustomFields] (state, { customFields }) {
    state.customFields = customFields
  },
  [MUTATION_TYPES.addCustomField] (state, { customField }) {
    state.customFields.push(customField)
  },
  [MUTATION_TYPES.setCustomField] (state, { customField }) {
    state.customField = customField
  }
}

const actions = {
  async loadCustomFields ({ commit, dispatch }) {
    const { data: customFields } = await startLoading(dispatch, 'custom:fields:fetch', () => api.getCustomFields())

    commit(MUTATION_TYPES.setCustomFields, { customFields })
  },
  async saveCustomField ({ commit, dispatch }, { customFieldData }) {
    const { data: customField } = await startLoading(dispatch, 'custom:field:save', () => api.postCustomField({ customFieldData }))

    commit(MUTATION_TYPES.setCustomField, { customField })
  },
  async loadCustomField ({ commit, dispatch }, { id }) {
    commit(MUTATION_TYPES.setCustomField, { customField: null })
    if (!id) return

    const { data: customField } = await startLoading(dispatch, `custom:field:${id}:fetch`, () => api.getCustomField({ id }))

    commit(MUTATION_TYPES.setCustomField, { customField })
  },
  async updateCustomField ({ commit, dispatch }, { customFieldData, id }) {
    const { data: customField } = await startLoading(dispatch, `custom:field:${id}:save`, () => api.putCustomField({ customFieldData, id }))

    commit(MUTATION_TYPES.setCustomField, { customField })
  }
}

const getters = {
  customFieldById: ({ customFields }) => (fieldId) => customFields.find(({ id }) => id === fieldId)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
