import { Expose } from "class-transformer";

export class CImportedInvoice {
  @Expose() contractId: number
  @Expose() thirdPartyId: number
  @Expose() invoiceNumber: string
  @Expose() amount: number
  @Expose() invoiceDate: Date
  @Expose() dueDate: Date
}
