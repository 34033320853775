import { connection } from "./connection";
import omit from "lodash/omit";

export default {
  getThreads() {
    return connection.get("portal/messages");
  },

  getThread({ id }) {
    return connection.get(`portal/messages/${id}`);
  },

  postMessage({ data }) {
    const formData = new FormData();
    const dataWithoutFiles = omit(data, "files");
    formData.append("message", new Blob([JSON.stringify(dataWithoutFiles)]));
    Array.from(data.files).forEach(file => {
      formData.append("file", file);
    });
    return connection.post(`portal/messages`, formData);
  },

  patchMarkRead({ ids }) {
    return connection.patch("portal/messages/read", ids);
  }
};
