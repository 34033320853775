import api from "@/api";
import { createActionHelpers } from "vuex-loading";
const { startLoading } = createActionHelpers({ moduleName: "loading" });

const actions = {
  async loadResourceFile({ dispatch }, { id }) {
    await startLoading(dispatch, `resource:${id}:download`, () =>
      api.getResourceFile({ id })
    );
  }
};

export default {
  namespaced: true,
  actions
};
