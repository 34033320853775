import { connection } from '@/api/connection'
import { pathBuilder } from '@/helpers'

const { get, put, post } = connection

const controllerPath = ({
  contractId,
  action,
  thirdPartyId
}) => pathBuilder`portal/factoring/contracts${contractId}third-parties${action}${thirdPartyId}`

export default {
  getFactoringThirdParties: ({ contractId }) => get(controllerPath({ contractId })),
  getFactoringThirdPartyClassifiers: ({ contractId }) => get(controllerPath({ contractId, action: 'classifiers' }))
}
