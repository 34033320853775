import { connection, idAuthConnection } from "./connection";

export default {
  async postAuth({ userData }) {
    // return await connection.post("auth", userData);
    return await connection.post("passwordauth", userData);
  },

  async getMobileIdAuth({ phone, idCode }) {
    return await connection.get(`mobidauth/${phone}/${idCode}`);
  },

  async getMobileIdPoll({ sessCode }) {
    return await connection.get(`mobidpoll/${sessCode}`);
  },

  async getSmartIdAuth({ idCode, countryCode }) {
    return await connection.get(`smartidauth/${countryCode}/${idCode}`);
  },

  async getSmartIdPoll({ sessionID }) {
    return await connection.get(`smartidpoll/${sessionID}`);
  },

  async getIdCardAuth() {
    return await idAuthConnection.get("idauth");
  },
  async postRefreshSession() {
    return await connection.post(
      "refreshSession",
      sessionStorage.getItem("refreshToken")
    );
  },
  async postExpireSession() {
    return await connection.post("expireSession");
  },
  getLoginReferred: async ({ referredId }) =>
    await connection.get(`portal/auth/${referredId}`)
};
