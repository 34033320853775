import { loanStatuses } from "@/const";
import moment from "moment";

const { inserted, matured, signed, pending } = loanStatuses;

export function filterOutdated(date) {
  return moment().diff(date, "months") > 3;
}

export const toUpperCase = (value = "") =>
  value.replace(/([a-z])([A-Z])/g, "$1_$2").toUpperCase();

export const disabledLoanStatuses = [inserted, matured, signed, pending];

export const valueInRange = (value, min, max) => Math.max(min, Math.min(value, max))

export const calculationTypes = (typesOptions = [], currency) => {
  const CALCULATION_TYPES = {
    "FIXED_SUM": currency,
    "PERCENTAGE_FROM_AMOUNT": '%',
    "PERCENTAGE_FROM_LIMIT": '%'
  }
  return typesOptions
    .map(type => ({ value: type, text: CALCULATION_TYPES[type] }))
    .filter(({ text }) => Boolean(text))
}

export const convertFee = ({ feeValue, isPercentage, amountValue }) => {
  if (!feeValue) return 0
  if (isPercentage) {
    return feeValue / amountValue
  }
  return feeValue * amountValue
}

export const parseBoolean = (value = 'false') => JSON.parse(value)

export const findCustomerMainContact = (contacts = [], defaultValue) =>
  (contacts.find(({ main }) => main) ?? contacts[0])?.value ?? defaultValue
export const findCustomerMainAddress = (addresses = [], defaultValue) =>
  (addresses.find(({ isDefault }) => isDefault) ?? addresses[0]) ?? defaultValue
export const findCustomerMainAccount = (accounts = [], defaultValue) =>
  (accounts.find(({ generalAccount }) => generalAccount) ?? accounts[0])?.accountExternalNumber ?? defaultValue

export const createBlobFromObject = object => new Blob([JSON.stringify(object)], { type: 'application/json' })

export const pathBuilder = (strings, ...params) => {
  const [rootPath, itemPath] = strings
  const [rootId, action, actionId] = params
  return [rootPath, rootId, itemPath, action, actionId].filter(Boolean).join('/')
}
