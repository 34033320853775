import { connection } from "@/api/connection";

export default {
  getRelationList: () => connection.get("portal/relations"),
  postRelation: ({ relationData }) =>
    connection.post("portal/relations", relationData),
  putRelation: ({ relationId, relationData }) =>
    connection.put(`portal/relation/${relationId}`, relationData),
  deleteRelation: ({ relationId }) =>
    connection.delete(`portal/relation/${relationId}`)
};
