import api from "@/api";
import { createActionHelpers } from "vuex-loading";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    accounts: [],
    transactions: [],
    transactionsTotalPages: null,
  },

  getters: {
    prepaymentAccounts: ({ accounts }, getters, rootState, rootGetters) =>
      (accounts.filter(
          ({ accountTypeId, accountEntries }) =>
            /PREPAYMENT/i.test(
              rootGetters["classifiers/classifierById"](
                "accountTypes",
                accountTypeId
              ).name
            ) && accountEntries.length
        )) ||
      [],
    accountById: ({ accounts }) => accountId =>
      (accounts.find(({ id }) => id === Number(accountId))) ||
      {},
    accountByRef: ({ accounts }) => reference =>
      (accounts.find(
          ({ referenceNumber }) => referenceNumber === reference
        )) ||
      {},
  },

  actions: {
    async loadAccounts ({ commit, dispatch }) {
      commit("SET_ACCOUNT_TRANSACTIONS", []);
      commit("SET_ACCOUNTS", { accounts: [] })

      const { data: accounts } = await startLoading(dispatch, 'accounts:fetch', () => api.getAccounts())

      commit("SET_ACCOUNTS", { accounts })
    },
    async loadAccountTransactions(
      { commit, dispatch },
      { accountId, filters }
    ) {
      commit("SET_ACCOUNT_TRANSACTIONS", []);
      if (filters.paymentViewMode) {
        const { data } = await startLoading(
            dispatch,
            `account:${ accountId }:transactions:fetch`,
            () => api.getAccountPayments({ accountId, filters })
        );
        commit("SET_ACCOUNT_TRANSACTIONS", { content: data });
      } else {
        const { data } = await startLoading(
            dispatch,
            `account:${ accountId }:payments:fetch`,
            () => api.getAccountTransactions({ accountId, filters })
        );
        commit("SET_ACCOUNT_TRANSACTIONS", data);
      }
    },
  },

  mutations: {
    SET_ACCOUNT_TRANSACTIONS(state, { content = [], totalPages }) {
      state.transactions = content;
      state.transactionsTotalPages = totalPages;
    },
    SET_ACCOUNTS(state, { accounts }) {
      state.accounts = accounts
    }
  }
};
