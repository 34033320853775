import i18n from "@/i18n";

export default {
  namespaced: true,

  getters: {
    targetLocale: (state, getters, rootState, rootGetters) => {
      const systemLocale = rootState.settings?.system?.language
      const userLocale = rootGetters["classifiers/classifierById"](
        "languages",
        rootState.customer?.customer?.languageId
      ).code;

      const userSettingsLocale = rootGetters["classifiers/classifierById"](
        "languages",
        rootState.settings?.settings?.languageId
      ).code;

      return userSettingsLocale ?? localStorage.getItem("locale") ?? userLocale ?? systemLocale
    }
  },

  actions: {
    setLocale({ state, commit, dispatch, getters }) {

      if (getters.targetLocale !== i18n.i18next.language) {
        i18n.i18next.changeLanguage(getters.targetLocale);

        commit("SET_LOCALE", { locale: getters.targetLocale });
        dispatch("classifiers/loadClassifiers", null, { root: true });
      }
    }
  },

  mutations: {
    SET_LOCALE(state, { locale }) {
      localStorage.setItem("locale", locale);
    }
  }
};
