import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: "loading" });

const state = {
  payment: null,
}

const mutations = {
  SET_PAYMENT (state, { payment }) {
    state.payment = payment
  }
}

const actions = {
  async loadPayment ({ state, commit, dispatch }, { paymentId, type }) {
    const { data: payment } = await startLoading(dispatch, `payments:${paymentId}:fetch`, () => api.getPaymentData({ paymentId, type }))

    commit('SET_PAYMENT', { payment })
  },
  async loadPaymentDocument(
    { dispatch },
    { paymentId }
  ) {
    await startLoading(dispatch, `payments:${paymentId}:pdf;download`, () =>
      api.getPaymentDataPDF({ id: paymentId })
    );
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
