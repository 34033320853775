import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import { orderBy } from "lodash";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    threads: [],
    thread: []
  },

  getters: {
    unreadCount: state =>
      state.threads.filter(
        ({ inboxReaderStatusId, direction }) =>
          inboxReaderStatusId === 2 && direction === "out"
      ).length || undefined
  },

  actions: {
    async loadThreads({ dispatch, commit }) {
      commit("SET_THREADS", { threads: [] });
      const response = await startLoading(dispatch, "threads:fetch", () =>
        api.getThreads()
      );
      commit("SET_THREADS", { threads: response.data });
    },

    async loadThread({ dispatch, commit }, { id }) {
      commit("SET_THREAD", { thread: [] });
      const response = await startLoading(
        dispatch,
        `thread:${id}:fetch`,
        () => {
          return api.getThread({ id });
        }
      );

      commit("SET_THREAD", { thread: response.data });
    },

    async createMessage({ dispatch, commit }, { data }) {
      const response = await startLoading(dispatch, "message:save", () => {
        return api.postMessage({ data });
      });
      return response.data.id;
    },

    async loadAttachmentFile({ dispatch }, { id }) {
      await startLoading(dispatch, `message:attachments:${id}:fetch`, () => {
        return api.getResourceFile({ id });
      });
    },
    async markRead({ dispatch }, { ids }) {
      await startLoading(dispatch, `message:read:save`, () => {
        return api.patchMarkRead({ ids });
      });
      dispatch("loadThreads");
    }
  },

  mutations: {
    SET_THREADS(state, { threads }) {
      state.threads = orderBy(threads, ["createdAt"], ["desc"]);
    },
    SET_THREAD(state, { thread }) {
      state.thread = thread;
    }
  }
};
