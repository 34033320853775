import permissions from "@/modules/permissions/directive";
import { guardRoute } from "@/modules/permissions/guard";

export default {
  install(Vue, options) {
    Vue.directive("fi-permissions", permissions);

    Vue.mixin({
      beforeRouteEnter: guardRoute
    });
  }
};
