import store from "@/store";

const { state } = store;

export function guardRoute(to, from, next) {
  const isVisible =
    !to.meta.visible ||
    to.meta.visible.some(permission =>
      state.auth.permissions.isExist(permission)
    );

  next(isVisible);
}
