<template lang="pug">
router-view.app(
  :class = '{ "_menu-open": visibleMenu }'
  :key   = '$route.path'
)
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "app",

  data() {
    return {
      languagesOptions: [
        { code:"et", id:1, human:"et", name:"et" },
        { code:"ru", id:2, human:"ru", name:"ru" },
        { code:"en", id:3, human:"en", name:"en" },
        { code:"lt", id:4, human:"lt", name:"lt" },
        { code:"lv", id:6, human:"lv", name:"lv" },
        { code:"sv", id:7, human:"sv", name:"sv" }
      ],
    };
  },
  
  computed: {
    ...mapState(["visibleMenu"]),
    ...mapState("auth", ["isLoggedIn"]),
    ...mapState("settings", ["allLanguages", "settings"]),
    ...mapState("customer", ["customer"]),
    ...mapGetters('customer', ['activeCustomer']),
  },

  watch: {
    isLoggedIn: {
      async handler(value) {
        if (!value) return;

        await Promise.all([
          this.loadClassifiers(),
          this.loadCustomer(),
          this.loadThreads()
        ]);
        await this.loadProducts({ sectorId: this.activeCustomer?.sectorId, segmentId: this.activeCustomer?.segmentId })
        await this.loadDepositContracts();
        await this.loadFactoringContracts()
        await this.loadLoans();
      },
      immediate: true
    },
    customer: {
      async handler(value) {
        if (!value) return;
        await this.loadProducts({ sectorId: this.activeCustomer?.sectorId, segmentId: this.activeCustomer?.segmentId })
      },
      immediate: true
    }
  },

  async created() {
    await this.loadSystemSettings();
    await this.loadSystemLogo();

    if (!this.allLanguages.length) await this.getAllLanguages()
    if (this.isLoggedIn) {
      await this.getBackofficeSettings();
      await this.changeLangFromLoginPage(this.$route.query.lang, this.allLanguages)
    }
  },

  methods: {
    ...mapActions("loans", ["loadLoans"]),
    ...mapActions("factoring", ["loadFactoringContracts"]),
    ...mapActions("deposits", ["loadDepositContracts"]),
    ...mapActions("classifiers", ["loadClassifiers"]),
    ...mapActions("settings", ["loadSystemSettings", "loadSystemLogo", "getBackofficeSettings", "updateSettings", "getAllLanguages"]),
    ...mapActions("customer", ["loadCustomer"]),
    ...mapActions("messages", ["loadThreads"]),
    ...mapActions("products", ["loadProducts"]),
    async changeLangFromLoginPage(local, arrayOfLagnuages) {
      if (local && !this.settings.languageId) {
        const localLang = arrayOfLagnuages.filter(el => el.name === local)
        const data = { languageId: localLang[0].id }
        await this.updateSettings({ data })
      } else {
        this.$router.push({ query: {} })
      }
    }
  }
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '/stylus/common.styl';
@import '/stylus/custom.styl';
</style>
